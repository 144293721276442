@import '../../../utils/variables';

.BookingModal {
  &__info {
    &-details {
      padding-top: 0.5rem;
    }

    &-edit-btn {
      text-align: start;
      cursor: pointer;
      background-color: #fff;
      border: none;
      font-size: 1rem;
      padding-bottom: 1.5rem;
      padding-left: 0;

      &:hover {
        color: #0161a5;
      }

      & i {
        padding-left: 0.5rem;
        font-size: 1.1rem;
      }
    }

    &-passengers {
      padding-top: 0.6rem;
      font-size: 0.95rem;
      letter-spacing: 1px;
      font-weight: 500;
    }

    &-header {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      font-weight: 900;
      padding-top: 1rem;
      height: 2.5rem;
      margin-bottom: 0.1rem;
      color: #131313;

      .fa-search {
        padding-right: 0.5rem;
      }
    }

    &-text {
      margin: 0;
      display: flex;

      &-notes {
        display: block;
        box-sizing: border-box;
        width: 95%;
        min-height: 10rem;
        resize: none;
        overflow-y: hidden;
        border: 0;
        outline: 0;
        background: rgb(241, 241, 241);
        border-radius: 20px;
        padding: 20px;
        margin-top: 0.5rem;
        color: #343434;
      }
    }

    &-status {
      display: flex;
      align-items: center;
      font-size: 0.9rem;

      & .BookingView__expl-mark {
        font-size: 1.6rem;
        font-weight: 500;
      }
    }

    &-valid {
      color: $completed;
      font-size: 1.2rem;
      margin-left: 0.5rem;

      &.hide {
        display: none;
      }
    }

    &-invalid {
      color: $needsreview;
      font-size: 1.2rem;
      margin-left: 0.5rem;

      &.hide {
        display: none;
      }
    }

    &-externalLink {
      display: flex;

      & a {
        color: #002b49;
      }

      & a:hover {
        color: #0161a5;
      }

      & i {
        padding-left: 0.6rem;
        font-size: 1.1rem;
      }
    }
  }

}