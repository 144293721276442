.Products{
  &__container{
    padding: 1.5rem;
    overflow: scroll;
    height: 100vh;
    .card{
      .card-header{
        background-color: transparent;
        border: none;
        padding: 1.5rem 0rem 1rem 2rem;
        .heading_styles{
          display: flex;
          flex-direction: column;
          font-size: 1.6rem;
          & i {
            font-size: 1.3rem;
            padding-bottom: 1rem;
            cursor: pointer;
          }
          & span {
            font-size: 1.6rem;
            padding-left: 0.5rem;
          }
        }
      }
    }
  }
    &__main{
      margin: 0 2rem 2rem 2rem;
    }
    &__header{
      padding-bottom: 2rem;
      padding-top: 1rem;
      &_create-btn{
        background-color: white;
        padding: 0.5rem 2rem;
        font-size: 0.9rem;
        border: 1px solid #002b49;
        margin-left: 1rem;
      }
    }
}

.show_table_cont {
  .display_price {
    display: flex;
    flex-direction: column;
    button {
      width: 40%;
      margin-left: 30%;
      margin-top: 2%;
      background-color: white;
    }
  }

  .name_cont {
    display: flex;
    justify-content: space-between;
  }

  .name_active {
    width: 30px;
    height: 96px;
    margin-top: -6px;
    margin-bottom: -6px;
    border-radius: 8px 0 0 8px;
    border-left: 1px solid;
    border-right: 1px solid;
    background-color: rgb(120, 233, 165);
    margin-left: -2px;
  }
  .name_inactive {
    width: 30px;
    height: 96px;
    margin-top: -6px;
    margin-bottom: -6px;
    border-radius: 8px 0 0 8px;
    border-left: 1px solid;
    border-right: 1px solid;
    background-color: rgb(230, 230, 230);
    margin-left: -2px;
  }

  .name_text_cont {
    padding: 0 12px;
    align-self: center;
    width: 100%;
  }

  .rc-table-row {
    height: 80px;
  }

  .rc-table-content {
    margin-top: 35px;
  }

  .rc-table-tbody {
    .rc-table-cell {
      text-align: center;
      border: 1px solid #5c5b5b;
      border-left: none;
      overflow-y: auto;
      height: 90px;
      &:first-of-type {
        border-radius: 10px 0 0 10px;
        border-left: 1px solid #5c5b5b;
      }
      &:last-of-type {
        border-radius: 0 10px 10px 0;
      }
    }
  }

  .rc-table-thead {
    .rc-table-cell {
      padding-top: 0;
      padding-bottom: 16px;
      border-bottom: 1px solid #5c5b5b;
      text-align: center;
      color: #131313;
      font-weight: 600;
    }
  }

  .rc-table {
    table {
      border-collapse: separate;
      border-spacing: 0 10px;
      border-top: 1px solid #5c5b5b;
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      &:nth-child(1) {
        border-spacing: 0 20px;
      }
      .product-availability{
        font-size: 0.95rem;
      }
      & a{
        text-decoration: none;
        background-color: #fff;
        color: #131313;
        font-weight: bold;
        border-radius: 4px;
        border: 1px solid #7c7c7c;
        height: 40px;
        width: 100%;
        max-width: 100px;
        padding: 0.5rem 2rem;
        font-size: 0.9rem;
      }
    }
  }
  .Products__header {
    display: flex;
    align-items: center
  }
  
  .Products__header_create-btn {
    background-color: #ff6262;
    margin-top: 18px;
    border: transparent;
    color: white;
    font-weight: 600;
    border-radius: 4px;
  }
.mg {
  margin-bottom: 20px;
}
.text_field .MuiOutlinedInput-input {
  padding: 8px 14px;
  font-size: 15px; 
}
.name-column {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  scroll-behavior: none;
}
}
