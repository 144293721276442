.Reservations {
  &__email {
    width: 50%;
    margin-left: 2rem;
    display: flex;
    margin-bottom: 2rem;

    &-btns {
      display: flex;
      justify-content: center;

      & button {
        min-width: 120px;
        background-color: #002b49;
        border: 0.1rem solid;
        color: white;
        padding: 0.25rem 2.2rem;
        border-radius: 6px;
        margin: 1.2rem 0.8rem 0 0.8rem;

        &:disabled {
          background-color: #002b49b5;
          color: rgba(255, 255, 255, 0.86);
        }
      }
    }

    & form {
      position: fixed;
      display: flex;
      flex-direction: column;
      width: 31%;
    }


    &-contact {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 0.3rem;

      &-select {
        width: 87%;
      }

      & input {
        width: 87%;
        margin-top: 0.1rem;
      }

      & i {
        margin-bottom: 0.7rem;
        font-size: 1.2rem;

        &:hover {
          cursor: pointer;
        }

      }
    }

    &-subject {
      margin-top: 0.3rem;
      margin-bottom: 0.5rem;

    }
  }
}