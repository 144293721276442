.News{
    &__container{
      padding: 1.5rem;
    }
    &__main{
      margin: 0 2rem 2rem 2rem;
      padding-top: 3vh;
    }
    &__header{
      padding-bottom: 2rem;
      padding-top: 1rem;
      &_create-btn{
        background-color: white;
        padding: 0.5rem 2rem;
        font-size: 0.9rem;
        border: 1px solid #002b49;
        margin-left: 1rem;
      }
    }
}

/* News.scss */
.card-header {
  border-bottom: none;
}

.mobile-view {
  display: block;
}

.desktop-view {
  display: none;
}

.news-wrapper {
  margin-top: 24px;
}

.news-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 16px;
  border: 1px solid tomato;
  border-radius: 4px;
}

.news-title {
  font-size: 18px;
  font-weight: bold;
}

.news-description {
  margin-top: 8px;
  font-size: 14px;
  color: #555;
}

.news-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.news-date {
  font-size: 12px;
  color: #777;
}

.news-status {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.badge-green {
  background-color: green;
  color: white;
}

.badge-red {
  background-color: red;
  color: white;
}

.badge-blue {
  background-color: blue;
  color: white;
}

.no-data-message {
  margin-top: 24px;
  text-align: center;
  color: tomato;
}

.action-button {
  background-color: tomato;
  color: white;
  padding: 3px 10px !important;
  border: none;
  cursor: pointer;
  margin-top: 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.action-button:hover {
  background-color: darken(tomato, 10%);
  transform: scale(1.05);
}

.edit-button {
  margin-right: 8px;
}

.archive-button {
  margin-right: 8px;
}

.activate-button {
  margin-right: 8px;
}

.delete-button {
  margin-right: 8px;
}
@media (min-width: 768px) {
  .news-table {
      width: 100%;
      border-collapse: collapse;
  }
  
  .news-table th {
      font-weight: bold;
  }
  
  .mobile-view {
      display: none;
  }
  
  .desktop-view {
      display: block;
  }
  
  .news-table th,
  .news-table td {
      padding: 12px;
      text-align: left;
  }
  
  .action-button {
      background-color: tomato;
      color: white;
      padding: 8px 16px;
      border: none;
      cursor: pointer;
      margin-right: 8px;
      border-radius: 4px;
      transition: background-color 0.2s ease, transform 0.2s ease;
  }
  
  .action-button:hover {
      background-color: darken(tomato, 10%);
      transform: scale(1.05);
  }
  
  .archive-button {
      background-color: tomato;
  }
  
  .activate-button {
      background-color: tomato;
  }
  
  .news-wrapper {
      margin-top: 24px;
  }
  
  .badge {
      display: inline-block;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
  }
  
  .badge-green {
      background-color: green;
      color: white;
  }
  
  .badge-red {
      background-color: red;
      color: white;
  }
  
  .badge-blue {
      background-color: blue;
      color: white;
  }
  
  .no-data-message {
      margin-top: 24px;
      text-align: center;
      color: tomato;
  }
}
