.BookingModal {
  &__tour-dropdown {
    color: rgba(0, 0, 0, 0.87);
    border: 0.1rem solid rgba(200, 196, 196, 0.74);
    border-radius: 0.25rem;
    position: relative;
    &-header {
      padding: 0 0.5rem;
      padding-right: 0.6rem;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-body {
      display: none;
      &.open{
        position: absolute;
        margin-top: 0.6rem;
        background-color: white;
        display: flex;
        flex-direction: column;
        height: 50vh;
        overflow: scroll;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        left: -2px;
        right: -2px;
        border: 0.1rem solid rgba(200, 196, 196, 0.74);
        animation: growDown 200ms ease-out forwards;
        transform-origin: top center;
        z-index: 100;
      }
    }
    &-loader {
      display: flex;
      flex-direction: column;
      align-items: center;
        & h3 {
          font-weight: 300;
          font-size: 1.3rem;
          letter-spacing: 1px;
        }
        & i {
          color: #c45054;
          font-size: 4rem;
          margin-top: 2rem;
          margin-bottom: 1.5rem;
        }
    }

    &-item {
      padding: 0.6rem 1.5rem;
      &:hover{
        cursor: pointer;
        background-color: #e05f6309;
      }
    }
    &-city{
      display: inline-block;
      padding-top: 0.5rem;
      padding-left: 0.9rem;
      color: #c45054;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: end;
  
    }
    &-icon {
      font-size: 13px;
      color: #c45054;
      transform: rotate(0deg);
      transition: all .2s ease-in-out;
        &.open {
          transform: rotate(90deg);
      }
    }
  }
}

@keyframes growDown {
  0% {
      transform: scaleY(0);
      opacity: 0;
  }
  80% {
    transform: scaleY(0.9);
      opacity: 0.9;
  }
  100% {
      transform: scaleY(1);
      opacity: 1;
  }
}