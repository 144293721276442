.gridContainer {
  position: relative;
  height: 100%;

  .toolsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 8px;

    &-search {
      display: flex;
      align-items: center;

      & button {
        margin-left: 0.5rem;
      }
    }
  }

  div.spinner-border {
    position: absolute;
    right: 0;
    top: 0;
    color: #ff6262;
  }
}

.gridComponent {
  height: 100%;
  width: 100%;
  overflow-x: auto;

  ul {
    padding-left: 12px;

    li {
      line-height: 1.5;
    }
  }

  .ag-cell-wrap-text {
    word-break: break-word;
  }
}

.ag-row-footer .ag-group-value {
  font-weight: bold !important;
}
.ag-row-footer .ag-cell-value {
  font-weight: bold !important;
}