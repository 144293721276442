.coordinates {
    margin-top: 20px;
    width: 100%;
    flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
  }

  .places {
    width: 100%;
    box-sizing: border-box; /* Include padding and border in the width */
    padding-right: 20px; /* Add padding to create space between places and drop-down on larger screens */
  }

  .coord-container {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
  }

  .drop-down {
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box; /* Include padding and border in the width */
  }

  .map {
    margin-top: 20px;
    margin-left: 0; /* Adjust margin-left to 0 for responsiveness */
    width: 100%;
  }
//   .start-icon{
//     font-size: xx-large;
//   }
  .places{
    display: flex;
    justify-content: space-between;
  }

  .custom-stops-container {
   display: flex;
   flex-direction: column;
   }
  .operational-hours {
    margin-left: 10px;
    width: 50%;
  }
.label {
  width: 50%;
}
   .mt {
    margin-top: 10px;
   }

   .image-preview {
    margin-left: 20px;
    width: 100%;
   }
   .s {
    display: flex;
   }
//    img {
//     width: 100%;
//     height: 350px;
//     margin-top: 10px;
//   }
  .loading {
    display: flex;
    justify-content: center;
  }