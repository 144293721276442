/* General styling for all events */
.rbc-event {
  text-align: center;
  color: black;
  border-radius: 5px; /* Optional: to give a rounded corner look */
  padding: 0 5px; /* Optional: to add some padding */
}

/* Specific styling for different event types */
.rbc-event-yellow {
  background-color: #F15C5A !important;
  border: 1px solid #F15C5A !important;
  color: black !important;
}

.rbc-event-orange {
  background-color: #4490D0 !important;
  border: 1px solid #4490D0 !important;
  color: black !important;
}

.rbc-event-blue {
  background-color: #232F55 !important;
  border: 1px solid #232F55 !important;
  color: white !important;
}

/* Ensuring background color for nested elements */
.rbc-event-content {
  background-color: inherit !important;
}
