@import "~bootstrap/scss/bootstrap.scss";

// $primary_navy

.views-selector-container .MuiAutocomplete-listbox {
  max-height: 50vh;
  padding: 8px 0;
  overflow: auto;
}

.tab-content {
  padding-top: 2rem !important;
}

.tab-pane {
  min-height: 50rem !important;
}

* {
  box-sizing: border-box;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.parent_cont {
  position: absolute;
  top: 45px;
  left: 130px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
}

.App {
  &__wrapper {
    overflow: hidden;
    height: 100%;

    &-sidebar-open {
      width: calc(100% - 240px);
      transition: 0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1);
      background-color: #f5f5f5;
      position: relative;
      float: right;
      height: 100%;
    }

    &-sidebar-closed {
      width: 100%;
      transition: 0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1);
      background-color: #f5f5f5;
      position: relative;
      float: right;
      height: 100%;
    }
  }
}
  