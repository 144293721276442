.custom-options option:not([value^="Custom Price"]):not([value="adult"]):not([value="child"]):not([value="infant"]) {
  display: none;
}

/* styles.css */
#currency-tabs .nav-tabs .nav-link {
  background-color: #007bff; /* Background color for inactive tabs */
  color: white; /* Text color for inactive tabs */
}

#currency-tabs .nav-tabs .nav-link.active {
  background-color: #0056b3; /* Background color for the active tab */
  color: white; /* Text color for the active tab */
}

#currency-tabs .nav-tabs .nav-link:hover {
  background-color: #0056b3; /* Background color on hover */
  color: white; /* Text color on hover */
}
