.Vehicles {
  &__container{
    height: calc(100% - 50px);
    padding: 0 8px;
    .card{
      height: 100%;
       overflow: scroll;
      .card-header{
        background-color: transparent;
        border: none;
        height: 50px;
        h2 {
          margin-bottom: 0;
        }
        .heading_styles{
          display: flex;
          flex-direction: column;
          font-size: 1.6rem;
          & i {
            font-size: 1.3rem;
            cursor: pointer;
          }
          & span {
            font-size: 1.6rem;
            padding-left: 0.5rem;
          }
        }
      }
    }
  }
  &__header{
    & button{
      background-color: #ff6262;
      color: #fff;
      border: 0px;
      border-radius: 5px;
      padding: 7px 12px;
      font-weight: 600;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    padding-top: 3.5rem;
    &-save {
      background: #f66262;
      color: #fff;
      border-radius: 4px;
      border: none;
      font-weight: bold;
      height: 40px;
      width: 100%;
      max-width: 100px;
      margin-right: 40px;
      &:disabled{
        background: #f66262bb;
      }
    }
    &-cancel {
      background-color: #706e6e;
      color: white;
      font-weight: bold;
      border-radius: 4px;
      border: 1px solid #706e6e;
      height: 40px;
      width: 100%;
      max-width: 100px;
    }
  }
  &__main{
    height: calc(100% - 110px);
  }
  &__editVehicle{
    width: 70%;
    margin: 0 auto;
  }
  &__error{
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
  }
  &__form{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 3rem;
      font-size: 0.9rem;
      .form-label {
       font-size: 1rem;
       margin-top: 0.8rem;
       font-weight: 600;
      }
      &-type{
        display: flex;
        flex-direction: column;
        &-label{
          & input{
            margin-right: 0.5rem;
          }
        }
      }
  }
  &__editBtn{
    color: #ff6262;
    font-weight: 600;
    text-decoration: none;
    &:focus{
      outline: none;
    }
    &:hover{
      color: #ff6262;
    }
  }
}

