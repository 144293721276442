@import './utils/variables';

.Bookings {
  &__main {
    padding: 0 8px;
    height: calc(100% - 50px);

    .bookings_grid_container {
      height: calc(100% - 60px);
    }
  }

  &__error {
    padding-top: 5rem;
    text-align: center;
    color: #002b49;

    & div {
      font-size: 1.1rem;
    }
  }

  &__column-display {
    display: flex;
    align-items: center;

    &-status {
      display: flex;
      align-items: center;
    }
  }

  &__notes {
    margin: 0;
    padding: 0 0 0 auto;
  }

  &__status-display {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.7rem;
    height: 0.7rem;
    margin-right: 0.5rem;
    border-radius: 0.4rem;
  }

  &__expl-mark {
    color: white;
    font-size: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__input-date-container {
    display: flex;
    flex-direction: column;
  }

  &__loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    & p {
      padding-top: 1rem;
      font-size: 1.2rem;
    }
  }
}


#input_border {
  width: 1em;
  height: 1em;
  margin-right: 0.6rem;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

#input_border:checked {
  background-color: #555;
  border: 1px solid #555;
}

#input_border_header {
  width: 0.8em;
  height: 0.8em;
  margin-right: 25px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

#input_border_header:checked {
  background-color: #555;
  border: 1px solid #555;
}

.Status-display {
  &__urgent {
    background-color: $urgent;
    border: 1px solid red;
  }

  &__pending {
    background-color: $pending;
  }

  &__needsreview {
    background-color: $needsreview;
  }

  &__noshow {
    background-color: $noshow;
  }

  &__cancelled {
    background-color: $cancelled;
  }

  &__confirmed {
    background-color: $confirmed;
  }

  &__completed {
    background-color: $completed;
  }

  &__refund_gs {
    background-color: $refund_ops;
  }

  &__refund_ops {
    background-color: $refund_attraction;
  }

  &__refund_attraction {
    background-color: $refund_gs;
  }

  &__pickuptimesent {
    background-color: $pickuptimesent;
  }

  &__informationrequested {
    background-color: $informationrequested;
  }

  &__completed-complaint {
    background-color: $completed-complaint;
  }
}

.rdt_TableHeadRow {
  div {
    padding: 2px;
    align-items: center;
    justify-content: center;

    div {
      div {
        white-space: normal;
        text-align: center;
      }
    }
  }
}

.rdt_TableRow {
  div {
    div:hover {
      overflow: visible;
      white-space: break-spaces;
    }
  }
}

#Bookings-information-loader {
  display: inline-block;
  width: 60px;
  height: 60px;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }

  border: 3px solid #ff6262;
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}