.Scheduling {
  &__droppables {
    display: flex;
    justify-content: space-around;
    width: 100%;

    & section:first-of-type {
      display: flex;
      flex-direction: column;
      width: 40%;
      min-width: 220px;
      border-right: 1px solid rgba(0, 0, 0, 0.231);
      padding-right: 1rem;

      @media only screen and (max-width: 991px) {
        border-width: 0px;
      }

      & .root {
        // display: flex;
        flex-wrap: wrap;
        flex: 1;
      }
    }

    & section:last-of-type {
      width: 85%;
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
      background-color: rgba(255, 255, 255, 0.7);
      padding: 1rem;
      border-radius: 30px;

      & .vehicles {
        border-radius: 20px;
        height: auto;
        padding: 1rem 1rem 0.5rem 1rem;
        margin-bottom: 0.6rem;
        display: flex;

        background: #E0EAFC;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #CFDEF3, #E0EAFC);
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #CFDEF3, #E0EAFC);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

    }

    &-droppable {
      @media only screen and (max-width: 991px) {
        // display: inline-table !important;
      }

      &-capacityOver {
        color: #e05f63;
      }

      &-bookings {
        display: flex;
        flex-wrap: wrap;
        width: 88%;
      }

      &-bookingsMinimize {
        display: flex;
        width: 0%;
      }

      &-vehicleInfo {
        width: 18%;
        padding: 0.5rem 1rem 0.5rem 0.3rem;
        margin-right: 0.8rem;
        font-size: 0.9rem;
        border-right: 1px solid rgba(0, 0, 0, 0.231);
        text-align: end;
        min-height: 80px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;

        @media only screen and (max-width: 991px) {
          width: 100% !important;
          border-right-width: 0cqi;
          min-height: 0px;
        }
      }

      &-vehicleInfoMinimize {
        width: 100% !important;
        min-height: 0px !important;
        border-right-width: 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px;

        @media only screen and (max-width: 991px) {
          // width: 100% !important;
          // margin: 0rem;
          // margin-bottom: .5rem;
        }
      }

      &-vehicleDeactive {
        opacity: .5;

        @media only screen and (max-width: 991px) {
          // width: 100% !important;
        }
      }

      &-navIcon {
        position: absolute;
        margin: 0px;
        font-size: x-large;
        margin-left: -10px;
        cursor: pointer;
        margin: -6px 0px 0px -25px;
        font-size: 1rem;
        color: rgb(100,100,100);
      }
    }
  }
}