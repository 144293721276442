.Scheduling {
  &__wrapper {

    & form {
      display: flex;
      width: 100%;
      padding-bottom: 0.5rem;

      @media only screen and (max-width: 991px) {
        display: block;
      }

      & .form-input {
        width: 20%;
        margin-right: 4rem;

        @media only screen and (max-width: 991px) {
          width: 100%;
          margin: 0rem;
          margin-bottom: .5rem;
        }
      }

      & button {
        background-color: #e05f63;
        border: 0.1rem solid #e05f63;
        color: white;
        padding: 0.1rem 2.2rem;
        border-radius: 5px;
        margin-right: 1.5rem;

        @media only screen and (max-width: 991px) {
          width: 100%;
          margin: 0rem;
          margin-bottom: .5rem;
        }
      }

      & button:disabled {
        background-color: #e05f638c;
        border: 0.1rem solid #e05f6331;
        color: white;
      }
    }
  }
}