.BookingModal{
  &__createBooking{
    width: 100%;
    &-calendar{
      margin-top: 2rem;
      width: 100%;
    }
    &-dropdown{
      color: rgba(0, 0, 0, 0.87);
      width: 100%;
      padding: 0 0.5rem;
      height: 2rem;
      border: 0.1rem solid rgba(200, 196, 196, 0.74);
      border-radius: 0.25rem;
      &:disabled{
        background-color: rgba(200, 196, 196, 0.09);
      }
    }
    &-capacity{
      font-size: 0.9rem;
      color: #002b49;
      padding-top: 0.4rem;
    }
    &-overbooking{
      display: flex;
      padding-top: 0.6rem;
      align-items: center;
      & span{
        padding-left: 0.4rem;
      }
    }
  }
}


