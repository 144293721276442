.news-slider-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
  }
  
  .news-slider {
    display: flex;
    align-items: center;
    background-color: tomato;
    // border-top: 2px solid white;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .slider-content {
    flex-grow: 1;
    overflow: hidden;
    color: white;
    font-size: 14px;
    font-weight: bold;
    tomato-space: nowrap;
    font-family: Arial, sans-serif;
  }

  .hide-button {
    background: tomato;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    transition: transform 0.3s;
    padding: 0px 10px;
  }
  
  .hide-button:hover {
    transform: scale(1.2);
  }
  
  .show-news-button {
    background-color: tomato;
    color: white;
    border: none;
    border-radius: 10px 10px 0 0;
    font-size: 16px;
    padding: 10px 20px;
    position: fixed;
    bottom: 0px;
    left: calc(90% - 70px);
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .show-news-button i {
    margin-left: 5px;
  }
  
  .show-news-button.animate {
    animation: slideUp 0.5s;
  }
  
  .show-news-button:hover {
    transform: scale(1.1);
  }
  
  @keyframes slideUp {
    0% {
      bottom: -60px;
      opacity: 0;
    }
    100% {
      bottom: 0px;
      opacity: 1;
    }
  }
  