.BookingModal {
  &__info-edit {
    &-container {

      & h2 {
        font-size: 1.3rem;
        font-weight: 400;
        text-align: center;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #e05f63;
      }
    }

    &-error {
      color: #e05f63;
      font-size: 0.8rem;
    }

    &-form {
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem;
      font-size: 0.8rem;
    }

    &-buttons {
      display: flex;
      width: 40%;
      justify-content: space-around;
      margin: 0 auto;
      padding-top: 2rem;

      & button {
        width: 40%;
        background-color: #e05f63;
        max-width: 110px;
        color: white;
        font-size: 0.9rem;
        border: none;
        border-radius: 0.25rem;
        font-weight: 500;

        &:hover {
          background-color: #c45054;
        }
      }
    }

    &-input {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 0.2rem;

      & input {
        color: rgba(0, 0, 0, 0.87);
        width: 100%;
        padding: 0 0.5rem;
        border: 0.1rem solid rgba(200, 196, 196, 0.74);
        border-radius: 0.25rem;

        &:focus {
          background-color: #e05f6309;
          outline: none;
        }
      }

      & textarea {
        color: rgba(0, 0, 0, 0.87);
        width: 100%;
        padding: 0 0.5rem;
        border: 0.1rem solid rgba(200, 196, 196, 0.74);
        border-radius: 0.25rem;

        &:focus {
          background-color: #e05f6309;
          outline: none;
        }
      }

      &-checkbox {
        padding-top: 0.5rem;
        display: flex;
        align-items: center;

        & span {
          padding-left: 0.3rem;
        }
      }
    }
  }
}