.Subtask {
  &__wrapper {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  &__task {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 0.5rem;
    min-height: 2.9rem;
    margin-bottom: 0.5rem;
    border: 0.1rem solid rgba(200, 196, 196, 0.74);
    font-size: 0.9rem;
    background-color: white;

    &-container {
      display: flex;
      align-items: center;
      width: 85%;
    }

    &-edit-form {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      & select {
        border: none;
        width: 30%;
        font-size: 0.9rem;

        &:focus {
          outline: none;
        }
      }

      & input {
        width: 50%;
        border: none;
        border-bottom: 1px solid rgba(65, 63, 63, 0.574);
        padding-left: 0.7rem;

        &:focus {
          outline: none;
        }
      }

      &-btn {
        & button {
          background-color: transparent;
          border: none;
          font-size: 1.4rem;
          margin-left: 0.2rem;
          color: #002b49;
        }

        & i {
          padding: 0 0.3rem;
        }

        & i:hover {
          color: #0161a5;
        }
      }
    }

    &-add-form {
      margin-top: 1.2rem;

      &-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 500;
        width: 100%;
        color: rgba(65, 63, 63, 0.5);
      }
    }

    &-status {
      margin-right: 1rem;
      margin-left: 0.5rem;
      border-radius: 50%;
      min-height: 28px;
      min-width: 28px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-expl-mark {
        color: white;
        font-size: 1.4rem;
        font-weight: 500;
      }

      &-check-mark {
        color: #002b49;
        font-size: 1rem;
      }
    }

    &-options {
      &-btn {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        padding: 0 0.5rem;
      }

      &-container {
        display: none;
      }

      &-container.open {
        display: flex;
        flex-direction: row;
        width: 15%;
        margin-right: 0.7rem;

        & button {
          background-color: transparent;
          border: none;
          font-size: 1.1rem;
          margin-right: 0.1rem;
          color: #002b49;
        }

        & i:hover {
          color: #0161a5;
        }
      }
    }
  }
}