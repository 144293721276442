.sessions{
  height: calc(100% - 50px);
  padding: 0 8px;
  .calendarContainer {
    height: calc(100% - 50px);
    display: flex;
    .calendar {
      width: 100%;
    }
  }
}
.seriesInfo {
  height: auto;
  width: 600px;
  padding: 16px;
  .info{
    display: flex;
    flex-direction: column;
    gap: 8px;
    .availabilityVehicle {
      position: relative;
      width: 100%;
      .availabilityHeader {
        display: flex;
        justify-content: space-between;
      }
    }
    .seriesActionButtons {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    
  }
}
.deleteConfirmationHeader {
  margin-bottom: 36px;
}
.deleteConfirmationButtons {
  display: flex;
  justify-content: space-between;
}


.dateRangeButton {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.dateRangeButton:hover {
  background-color: #0056b3;
}

.datePickerContainer {
  position: absolute;
  top: 40px; /* Adjust this value as needed */
  z-index: 1000; /* Ensure the date picker is above other elements */
  background: white; /* Ensures the date picker has a background */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
  border-radius: 4px; /* Optional rounded corners */
}
.applyButton {
  background-color: #ff6262; /* Example background color */
  color: white;
  border: none;
  padding: 8px 16px;
  width: 100px;
  cursor: pointer;
}

.applyRefressButton {
  background-color: #ff6262; /* Example background color */
  color: white;
  border: none;
  margin-left: 40px;
  padding: 8px 16px;
  width: 100px;
  cursor: pointer;
}

.dialogPaper {
  width: 600px;
  height: 432px;
  border-radius: 20px;
  padding: 20px;
}

.dialogTitle {
  font-weight: 600;
  font-size: 22px;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
}

.availabilityContainer {
  margin-bottom: 16px;
}

.availabilityHeader {
  display: flex;
  justify-content: space-between;
}

.availabilityTitle {
  font-weight: 600;
  font-size: 18px;
}

.availabilityDate {
  font-weight: 500;
  font-size: 15px;
  color: #757575;
}

.guideContainer {
  margin-bottom: 8px;
}

.slotContainer {
  margin-left: 16px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
}

.guideInfo {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-around;
}

.guideName {
  font-weight: semi-bold;
  font-size: 20px;
  color: black;
}

.availableSeats {
  color: #757575;
  font-size: 15px;
  margin-left: 5px;
  background: linear-gradient(251.54deg, #F15C5A 17.38%, #7A1036 177.05%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ratingContainer {
  display: flex;
  justify-content: space-around;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: 20px;
}
.ratingText {
  margin-top: 2px;
  margin-left: 5px;
  background: linear-gradient(251.54deg, #F15C5A 17.38%, #7A1036 177.05%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.closeButton {
  background: linear-gradient(251.54deg, #F15C5A 17.38%, #7A1036 177.05%);
  color: #fff !important;
  width: 100px;
  height: 35px;
  font-weight: 500;
  text-transform: none;
}
