.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    position: relative;
    background-color: #ffffff00;
    /* background-color: #fff; */
    width: 400px;
    max-width: 90%;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #00ffff00 !important;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .modal-close-icon {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .modal-content {
    margin-bottom: 20px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-action-button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    background-color: tomato;
    cursor: pointer;
  }
  
  .modal-action-button:hover {
    background-color: #b53232;
  }
  
  .modal-action-button:first-child {
    margin-left: 0;
  }

  .button {
      background-color: tomato !important;
      color: white !important;
  }
  