.BookingModal {
  &__fifty {
    margin-bottom: 1rem;
  }

  &__dropdown {
    padding: 0.5rem;
    width: 100%;
    max-width: 200px;
    margin-right: 24px;
    border: 0.1rem solid rgba(128, 128, 128, 0.5);
    text-transform: none;

    &:focus {
      outline: none;
    }
  }

  &__dropdown-list {
    width: 100%;
    max-width: 230px;
    margin-right: 24px;
    height: 42px;
    border: 0.1rem solid rgba(128, 128, 128, 0.5);
    padding: 0 0.5rem;
  }

}