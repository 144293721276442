$urgent: #e04653;
$pending : orange;
$needsreview : #e05f63;
$noshow : #b4b0b0;
$cancelled : #605c68;
$confirmed : #0096c7;
$completed : #b5e48c;
$refund_gs : #e4c18c;
$refund_ops : $refund_gs;
$refund_attraction : $refund_gs;
$informationrequested: rgb(254, 214, 69);
$pickuptimesent: #ccb6f7;
$completed-complaint: #72a348;