.editSession {
  padding: 24px 36px;
  border: 1px solid hsla(0, 0%, 10%, 0.4);
  border-radius: 4px;
  width: 50%;
  .sessionHeader {
    text-align: center;
    margin-bottom: 24px;
  }
  .sessionActivity {
    display: flex;
    justify-content: flex-end;
  }
  .sessionVehicles {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .selectedVehicles {
      width: 50%;
      .selectedVehicle {
        font-weight: 500;
        display: flex;
        align-items: center;
        background: lightblue;
        border: 1px solid black;
        margin-bottom: 8px;
        width: 100%;
        text-align: left;
        .selectedVehicleIcon {
          position: relative;
          right: 12px;
          cursor: pointer;
          color: hsl(0, 0%, 15%);
          &:hover {
            color: hsl(0, 0%, 50%);
          }
        }
        .selectedVehicleName {
          margin-bottom: 0;
          padding: 12px 12px;
          width: 100%;
        }
      }

    }
    .availableVehiclesContainer {
      width: 45%;
      .availableVehiclesFilter {
        margin-bottom: 12px;
      }
      .availableVehicles {
        max-height: 242px;
        overflow-y: scroll;
        .availableVehicle {
          display: flex;
          align-items: center;
          padding: 12px 12px;
          border: 1px solid black;
          margin-bottom: 8px;
          width: 100%;
          cursor: pointer;
          transition: all ease 0.2s;
          &:hover {
            background: hsl(0, 0%, 90%);
          }
          .availableVehicleIcon {
            margin-right: 4px;
          }
        }
      }
    }
  }
  .editSessionActions {
    display: flex;
    justify-content: space-between;
  }
  .totalRemaining {
    display: flex;
    align-items: center;
    min-height: 60px;
    .totalRemainingHeader {
      margin-right: 36px;
      margin-bottom: 0;
    }
    .totalRemainingEditor {
      width: auto;
      border: 1px solid black;
      display: flex;
      height: 40px;
      .totalRemainingAdd {
        min-width: 28px;
        font-weight: bold;
        font-size: 20px;
        height: 100%;
        border: none;
        background: hsl(0, 0%, 90%);
        &:hover {
          background: hsl(0, 0%, 80%);
        }
      }
      .totalRemainingNumber {
        min-width: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-bottom: 0;
      }
      .totalRemainingSubtract {
        min-width: 28px;
        font-weight: bold;
        font-size: 20px;
        height: 100%;
        border: none;
        background: hsl(0, 0%, 90%);
        &:hover {
          background: hsl(0, 0%, 80%);
        }
      }
    }
    
  }
}
.sessionChangesHeader {
  text-align: center;
  margin-bottom: 12px;
}
.sessionChanges {
  display: flex;
  justify-content: space-between;
}
.sessionSaveButtons {
  display: flex;
  justify-content: space-between;
}
.sessionChanges {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  .sessionBefore {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35%;
    .sessionHeaderBefore {
      border-bottom: 1px solid rgb(255, 98, 98);
      text-align: center;
      padding-bottom: 6px;
      margin-bottom: 24px;
    }
    .initialVehicles {
      .initialVehicle {
        font-weight: bold;
        display: flex;
        align-items: center;
        background: lightblue;
        border: 1px solid black;
        margin-bottom: 8px;
        width: 100%;
        text-align: left;
        padding: 10px 12px;
      }
    }
    .initialAvailability {
      display: flex;
      p {
        font-weight: bold;
      }
      .initialAvailabilityText {
        margin-right: 24px;
      }
    }
  }
  .sessionEastIcon {
    display: flex;
    align-items: center;
  }

  .sessionAfter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35%;
    .sessionHeaderAfter {
      border-bottom: 1px solid rgb(255, 98, 98);
      text-align: center;
      padding-bottom: 6px;
      margin-bottom: 24px;
    }
    .afterVehicles {
      
      .afterVehicle {
        font-weight: bold;
        display: flex;
        align-items: center;
        background: lightblue;
        border: 1px solid black;
        margin-bottom: 8px;
        width: 100%;
        text-align: left;
        padding: 10px 12px;
        
      }
    }
    .afterAvailability {
      display: flex;
      p {
        font-weight: bold;
      }
      .afterAvailabilityText {
        margin-right: 24px;
      }
    }
  }
}