.Bookings{
  &__header{
    display: flex;
    height: 100%;
    &-createBtn{
      background-color: #ff6262;
      color: #fff;
      border: 0px;
      border-radius: 5px;
      padding: 4px 8px;
      font-weight: 600;
      font-size: 0.9rem;
      min-width: 130px;
      margin: 0.5rem 1rem;
    }
    &-filterBtn{
      background-color: #ff6262;
      color: #fff;
      border: 0px;
      border-radius: 5px;
      padding: 4px 8px;
      font-weight: 600;
      font-size: 0.9rem;
      min-width: 60px;
      margin: 0.5rem 1rem;
    }
  }
}
#popover-filters {
  max-width: fit-content;
  min-width: 600px;
}
