.VehiclePanes {
  &__Pane {
    
    &-autoComplete {
      height: 25px;
      width: 25%;

        @media only screen and (max-width: 991px) {
          width: 100%;
          margin-bottom: 1em;
        }

        & > div > .css-16z3zeq-MuiInputBase-root-MuiInput-root {
            margin-top: 0px;
            font-size: .75rem;
            color: rgb(37, 37, 41)
        }
        & > div > .css-16z3zeq-MuiInputBase-root-MuiInput-root:before {
            border-bottom-width: 0px;
        }
        & > div > .css-16z3zeq-MuiInputBase-root-MuiInput-root:after {
            border-bottom-width: 0px;
        }

    }

  }
}