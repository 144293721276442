.BookingModal {
  display: flex;

  &__body {
    padding: 0 !important;
  }

  &__booking-modal-container {
    height: fit-content;
    width: 50rem;
    background-color: rgb(255, 255, 255);
    border: 0.15rem solid rgba(66, 65, 65, 0.133);
    border-top: none;
    padding-bottom: 0.5rem;

    a {
      color: #18191a;
      transition: all 0.3s;
    }

    a:hover {
      color: #5c73b1;
    }

    .tabbed {
      overflow-x: hidden;
    }

  }

  &__close-btn {
    font-size: 1.3rem;
    color: rgba(128, 128, 128, 0.555);
    cursor: pointer;
    position: absolute;
    right: 0px;
    background-color: transparent;
    border: none;
    padding-right: 1rem;
    padding-top: 0.3rem;
  }

  &__container {
    padding: 0 3rem 0.7rem;
    line-height: 1.8rem;
    font-size: 0.9rem;
  }

  &__createBooking-container {
    padding: 0.7rem 3rem;
    margin-top: 2.5rem;
    line-height: 1.8rem;
    font-size: 0.9rem;
  }

  &__header {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
  }

  &__tab {
    color: #ff6262 !important;
    background-color: white !important;
    height: 100%;
    font-size: 1.1rem;
    font-weight: 600;
    width: 100%;
    border: 0 !important;

    &:hover {
      background-color: #fd5d5a !important;
      color: white !important;
      border-radius: 0 !important;
    }
  }

  &__tab.nav-link.active {
    color: #f9f9f9 !important;
    background-color: #fd5d5a !important;
    border-radius: 0 !important;
    border: 0 !important;

    &:hover {
      background-color: #fd3834 !important;
    }
  }

}

.copyToClipboard {
  & span {
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      cursor: pointer;
      border-radius: 5px;
    }
  }
}

.image-column {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.image-column img {
  width: 500px;
  height: 400px;
  margin: 8px;
}

.btn-container {
  position: sticky;
  float: right;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}

.footer-container {
  margin-top: 90px;
  position: sticky;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  float: right;
  bottom: 0px; 
  right: 20px; 
  z-index: 1; 
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
}


.footer-container .model-btns {
  margin-top: 20px;
  text-align: center;
  border: transparent;
  background-color: #fd5d5a;
  width: 100px;
  margin-bottom: 16px; 
}
