.Reservations {
  &__table {
    width: 100%;
    table-layout: fixed;

    & .completed-booking {
      color: #cd575b;
    }

    & th {
      text-align: center;
      font-size: 0.9rem;
      padding-bottom: 0.8rem;
    }

    & td {
      padding: 0.2rem;
      font-size: 0.8rem;
      text-align: center;
    }

    & .checkbox {
      width: 1.5%;
    }

    & .td-column1 {
      border-bottom: 6px solid #f5f5f5;
      border-radius: 10px;
      padding: 1rem;
      font-size: 0.9rem;
      background-color: #002b49;
      color: white;
      width: 15%;
      vertical-align: top;

      &-scroll {
        position: sticky;
        top: 15px;
      }

      &-sumCheck {
        padding-top: 1.2rem;

        & input {
          margin-right: 0.4rem;
        }
      }

      & button {
        background-color: #e05f63;
        border: 0.1rem solid #e05f63;
        color: white;
        padding: 0.3rem 2rem;
        border-radius: 5px;
        margin-top: 1rem;
      }

      & button:disabled {
        background-color: #b44b4f;
        border: 0.1rem solid #b44b4f;
        color: white;
      }
    }

    & .td-column2 {
      border-bottom: 6px solid #f5f5f5;
      border-radius: 10px;
      padding: 1rem;
      font-size: 0.8rem;
      background-color: white;
      width: 85%;
    }

    & .data {
      width: 5%;

      &.status {
        width: 1%;
      }

      &.time {
        width: 1%;
      }

      &.guide {
        width: 2%;
      }

      &.date {
        width: 2%;
      }

      &.pax {
        width: 2%;
      }

      &.id {
        width: 1%;
      }

      &.id:hover {
        cursor: pointer;
      }
    }

    & tr:nth-child(even) {
      background-color: #f3f3f3;
    }

    & tr:hover {
      background-color: #002b4922;
    }

    &-error {
      text-align: center;
    }
  }
}