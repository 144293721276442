.EditForm {
  &__product-name {
    padding-bottom: 1.2rem;
    padding-left: 2rem;
    font-size: 1.2rem;
    padding-top: 0.2rem;
  }

  &__container {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  &__error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
  }

  &__buttons {
    display: flex;

    &-submit {
      background-color: #e05f63;
      border: 0.1rem solid #e05f63;
      color: white;
    }

    &-cancel {
      background-color: #706e6e;
      border: 0.1rem solid #706e6e;
      color: white;
    }

    & button {
      margin-right: 1.5rem;
      padding: 0.2rem 2.5rem;
      border-radius: 5px;
    }

    & button:disabled {
      background-color: #e05f638c;
      border: 0.1rem solid #e05f6331;
      color: white;
    }

  }

  &__navigation {
    width: 20%;

    &-btns {
      padding: 1.2rem;
      border: 1px solid #ccc;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      background-color: rgba(204, 204, 204, 0.089);
      box-shadow: 5px 5px 10px -6px rgba(41, 41, 41, 0.28);

      &-active {
        background-color: #002b49;
        color: #fff;
        border: 1px solid #ccc;
        padding: 0.2rem;
        border-bottom: none;

        &:last-of-type {
          border-bottom: 1px solid #ccc;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        &:first-of-type {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      &-inactive {
        background-color: white;
        border: 1px solid #ccc;
        padding: 0.2rem;
        border-bottom: none;

        &:last-of-type {
          border-bottom: 1px solid #ccc;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        &:first-of-type {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }

  }

  &__form {
    width: 75%;
    padding: 2rem;
    padding: 0 5rem;
    margin-bottom: 1rem;

    &-page1 {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 2rem;

      .form-label {
        font-size: 1.1rem;
        margin-top: 0.8rem;
      }

      &-type {
        display: flex;
        flex-direction: column;

        &-label {
          & input {
            margin-right: 0.5rem;
          }
        }
      }

      &-pickup {
        margin-top: 1.5rem;
        // margin-bottom: 1rem;
      }
    }

    &-page2 {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 2rem;

      .form-label {
        font-size: 1.1rem;
        margin-top: 0.8rem;
      }

      &-active {
        margin-top: 0.8rem;
      }

      &-pax {
        display: flex;
        align-items: flex-start;
      }
    }

    &-page3 {
      margin-bottom: 2rem;

      .form-label {
        font-size: 1.1rem;
        margin-top: 0.8rem;
      }

      &-header {
        margin-bottom: 1.2rem;
      }

      &-select {
        display: flex;

        & button {
          background-color: #002b49;
          border: 0.1rem solid #002b49;
          color: white;
          margin-left: 1rem;
          padding: 0.1rem 1.3rem;
          border-radius: 5px;
        }

        & select {
          width: 30%;
          padding: 0.3rem;
        }
      }
    }

    &-page4 {
      margin-bottom: 2rem;

      &-showBtn {
        background-color: #002b49;
        border: 0.1rem solid #002b49;
        width: 80%;
        color: white;
        padding: 0.3rem 1.3rem;
        border-radius: 5px;
        margin-bottom: 5rem;
      }

      &-refreshBtn {
        background-color: #002b49;
        border: 0.1rem solid #002b49;
        color: white;
        margin-left: 0.5rem;
        border-radius: 5px;
      }

      & table {
        width: 100%;

        th {
          font-weight: 500;
          color: #131313;
          text-align: center;
          padding-bottom: 0.6rem;
          padding-top: 0.5rem;

          &:last-of-type {
            text-align: end;
          }

          &:first-of-type {
            text-align: start;
          }
        }

        & tbody {
          & tr {
            border-bottom: 1px solid #eae8e8;

            &:last-of-type {
              border-bottom: none;
            }
          }

          & td {
            text-align: center;
            font-size: 0.9rem;
            padding-top: 0.4rem;
            padding-bottom: 1rem;
            color: rgb(92, 91, 91);

            &:last-of-type {
              text-align: end;
            }

            &:first-of-type {
              text-align: start;
            }
          }
        }
      }

      &-new {
        display: flex;
        flex-direction: column;
      }

      & table {
        margin-bottom: 1rem;
      }

      .form-label {
        font-size: 1.1rem;
        margin-top: 0.8rem;
      }

      &-dates {
        display: flex;
        align-items: flex-start;
      }

      &-juggle {
        margin-top: 0.8rem;
      }

      &-shared {
        margin-top: 0.8rem;

        &-select {
          margin-top: 0.8rem;
        }
      }

      &-price {
        display: flex;
        align-items: flex-start;

        .input-group {
          margin-bottom: 0.4rem;
        }
      }
    }

    &-page5 {
      margin-bottom: 4rem;

      .form-label {
        font-size: 1.1rem;
        margin-top: 0.8rem;
      }
    }
  }
}