[data-amplify-router] {
  min-width: 376px;
}
[data-amplify-authenticator] {
  background: hsla(0, 0%, 100%, 1.0);
  height: 100vh;
  [data-amplify-authenticator-signin] {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    .amplify-flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      fieldset {
        position: relative;
        margin-bottom: 80px;
        .amplify-flex {
          display: block;
          width: 100%;
        }
      }
    }
  }
  [data-amplify-authenticator-forcenewpassword] {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    .amplify-button--primary {
      width: 100%;
    }
    .amplify-button--link {
      display: flex;
      background-color: white;
      margin: auto;
      border: 0px;
      text-decoration: underline;
    }
    .amplify-flex {
      width: 100%;
    }
  }
}

[data-amplify-container] {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 104px;
  img {
    margin-bottom: 48px;
  }
}

.amplify-heading--3 {
  margin-bottom: 48px;
  text-align: center;
}

.amplify-visually-hidden {
  display: none;
}
.amplify-input {
  width: 100%;
  border: 1px solid hsla(0, 0%, 10%, 0.4);
  padding: 6px 12px;
  margin-bottom: 20px;
}

.amplify-passwordfield {
  position: relative;
    .amplify-field-group__outer-end {
      position: absolute;
      bottom: 27px;
      left: 85%;
      .amplify-field__show-password {
        border: none;
        background: none;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
}

.amplify-alert__dismiss {
  display: none;
}
.amplify-alert__icon {
  display: none;
}
.amplify-alert__body {
  color: red;
}
.amplify-text--error {
  color: red;
  text-align: center;
}
.amplify-alert--error {
  position: relative;
  bottom: 100px;
}

.amplify-button--primary {
  background: teal;
  color: white;
  border: none;
  font-weight: 400;
  text-align: center;
  align-self: auto;
  width: 70%;
  height: 56px;
  margin-bottom: 12px;
  font-size: 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

// .amplify-button--link {
//   background: teal;
//   color: white;
//   border: none;
//   font-weight: 400;
//   text-align: center;
//   align-self: auto;
//   width: 70%;
//   height: 56px;
//   margin-bottom: 12px;
//   font-size: 20px;
//   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
// }

[data-amplify-footer] {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 120px;
  button {
    border: none;
    background: none;
    text-decoration: underline;
  }
}

.amplify-loader {
  display: none;
}

[data-amplify-authenticator-resetpassword] {
  fieldset {
    > * {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .amplify-button--link {
      border: none;
      background: none;
      text-decoration: underline;
    }
    .amplify-textfield {
      width: 100%;
    }
  }
}