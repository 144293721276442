.heading_cont {
  position: absolute;
  top: 45px;
  left: 250px;
  display: flex;
  justify-content: center;
  height: calc(100vh - 116px);
  width: 100vw;
  .heading_styles {
    height: fit-content;
    width: fit-content;
    font-family: "Open Sans";
    font-size: 100px;
    padding: 11%;
    color: rgb(49, 48, 48);
  }
}
.dash-head{
  display: flex;
  padding: 1rem 2rem;
  justify-content: start;
  align-items: center;
  gap: 2rem;
  background-color: white;
  button{
    padding: 0.5rem 1rem;
    background-color: white;
    border: 1px solid lightgray;
    &:hover{
      background-color: lightgray;
    }
  }
}

.abandoned-listing{
  width: 100%;
  padding: 1rem 2%;
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  .card{
    h2{
      font-size: 19px;
    }
    max-width: 600px;
    p{
      font-size: 15px;
      margin-bottom: 2px;
    span{
      color: red;
    }
    }

    padding: 1rem;
    display: flex;
    flex-direction: column;
    button{
      padding: 0.5rem 1.5rem;
      background: red;
      color: white;
      border: none;
    }
  }
}
.category{
  border: 1px solid lightgray;
  background: white;
  border-radius: 10px;
  max-width: 300px;
  display: flex; gap: 1rem;
  align-items: center;
  cursor: pointer;
p{
  margin-bottom: 0;
}
  span{
    color: red;
    font-weight: bold;
  }
}
.category-listing{
  gap: 1rem;

}