.rbc {
  &-event {
    background: #ff6262;
    padding: 0px 4px;
    &-content {
      font-weight: 500;
      background: #ff6262;
      color: hsl(0, 0%, 95%);
    }
  }
}