.Reservations {
  &__logs {
    margin-top: 1.5rem;
    padding-bottom: 3rem;

    &-form {
      display: flex;

      & input {
        width: 20%;
        margin-right: 4rem;
      }

      &-btns {
        display: flex;

        &-search {
          background-color: #e05f63;
          border: 0.1rem solid #e05f63;
          color: white;
          padding: 0.1rem 2.2rem;
          border-radius: 5px;
        }

        &-hide {
          background-color: #002b49;
          border: 0.1rem solid #002b49;
          color: white;
          padding: 0.1rem 2.6rem;
          border-radius: 5px;
          margin-left: 2rem;

          &:disabled {
            background-color: #002b499c;
            border: 0.1rem solid #19588523;
            color: rgba(255, 255, 255, 0.75);
          }
        }
      }
    }
  }

  &__logsList {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;

    &-log {
      background-color: white;
      border: 1px solid #ced4da;
      border-radius: 5px;
      margin-bottom: 1rem;
      padding: 1.2rem;
      font-size: 0.9rem;
      width: 30%;
      margin-right: 1rem;
      box-shadow: 2px 4px 15px -5px rgba(0, 0, 0, 0.1);
    }
  }

  &__logsNotFound {
    padding-top: 1.7rem;
    padding-left: 0.2rem;
  }
}