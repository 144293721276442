.capsule {
  height: auto;
  &__text {
    margin: 0;
    font-size: 13px;
  }
  display: flex;
  flex-direction: column;
  &__metaInfo {
    display: flex;
    justify-content: space-between;
    height: auto;
  }
  &__statuses {
    display: flex;
    background: hsla(0, 0%, 90%, 0.2);
  }
  &__green {
    display: flex;
    justify-content: center;
    width: 40px;
    height: 16px;
    background: #26ad5f;
  }
  &__cancelled {
    display: flex;
    justify-content: center;
    height: 16px;
    background: #888;
    width: 40px;
  }
}
