.Scheduling {
  &__main {
    padding: 2rem 2rem 2rem 2rem;
    background-color: #f5f5f5;
  }

  &__topBtns {
    padding-bottom: 0.3rem;

    @media only screen and (max-width: 991px) {
      display: block;
    }

    select {
      display: inline;
      width: 25%;
      margin-right: 1.5rem;

      @media only screen and (max-width: 991px) {
        width: 100% !important;
        margin: 0rem;
        margin-bottom: .5rem;
      }
    } 

    button {

      @media only screen and (max-width: 991px) {
        width: 100% !important;
        margin: 0rem;
        margin-bottom: .5rem;
      }
    }
  }

  &__container {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;

    &-btn-saveChanges {
      background-color: #002b49;
      border: none;
      border-radius: 5px;
      color: white;
      padding: 0.4rem 2.2rem;
      min-width: 10%;

      &:hover {
        box-shadow: 0 3px #72a5ca7b;
        top: 2px;
      }

      &:disabled {
        background-color: #002b499c;
        color: rgba(255, 255, 255, 0.85);

        &:hover {
          box-shadow: none;
        }
      }
    }

    &-btn-reset {
      background-color: #e05f63;
      border: none;
      border-radius: 5px;
      color: white;
      padding: 0.4rem 2.2rem;
      min-width: 10%;
      margin-left: 1.5rem;

      &:hover {
        box-shadow: 0 3px #e27276c2;
        top: 2px;
      }
    }

    &-main {
      margin-top: 2rem;
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-around;
    }

    &-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 14.7vw);

      @media only screen and (max-width: 991px) {
        grid-template-columns: repeat(2, 53%);
      }
    }
  }

  &__loader {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 15%;
    margin-bottom: 4rem;

    & p {
      padding-top: 0.5rem;
    }
  }

  &__editBooking {

    &-Box {
      margin: 10px 0px;
    }

  }

  &__error {
    margin-top: 25%;
    text-align: center;
  }

  &__no-bookings {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 15%;
    margin-bottom: 4rem;

    & p {
      padding-top: 0.5rem;
    }
  }
}

#Scheduling-loader {
  display: inline-block;
  width: 60px;
  height: 60px;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }

  border: 3px solid #ff6262;
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}