.sessions{
  height: calc(100% - 50px);
  padding: 0 8px;
  .calendarContainer {
    height: calc(100% - 50px);
    display: flex;
    .calendar {
      width: 100%;
    }
  }
}
.seriesInfo {
  height: auto;
  width: 600px;
  padding: 16px;
  .info{
    display: flex;
    flex-direction: column;
    gap: 8px;
    .availabilityVehicle {
      position: relative;
      width: 100%;
      .availabilityHeader {
        display: flex;
        justify-content: space-between;
      }
    }
    .seriesActionButtons {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    
  }
}
.deleteConfirmationHeader {
  margin-bottom: 36px;
}
.deleteConfirmationButtons {
  display: flex;
  justify-content: space-between;
}

