.modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .text-area {
    resize: vertical;
    min-height: 150px; /* Increase the minimum height */
    padding: 8px;
  }
  
  .title-field {
    width: 300px; /* Increase the width */
  }
  
  .confirmation-modal {
    background: transparent;
    max-width: 400px; /* Adjust the max-width as needed */
    margin: 0 auto;
  }

.button {
    background-color: tomato !important;
    color: white !important;
}
  