.flex-grow-1 {
  flex-grow: 1;
}

.admin-form-wrapper {
  max-width: 400px;
  color: #000;
  .form-control {
    border-radius: 0;
    width: auto;
    @media (max-width: 767px) {
      width: 100% !important;
      text-align: center;
    }
  }
  .w-40 {
    width: 40px;
    padding: 3px;
    text-align: center;
  }
  .range-wrapper {
  }
  .range {
    input {
      width: 40px;
      height: 29px;
      padding: 10px;
      border-left: 0;
      border-right: 0;
      @media (max-width: 576px) {
        width: 33px !important;
      }
    }
    button {
      width: 30px;
      min-height: 29px;
      border: 1px solid #c1ccd3;
      background: transparent;
      @media (max-width: 576px) {
        width: 25px;
      }
    }
  }

  .availability-groups {
    border: 1px solid #c1ccd3;
    padding: 10px 7px 10px 17px;
    border-left: 0;
    width: 100%;
    position: relative;
    // max-height: 55px;
    // height: unset;
    // flex-wrap: wrap;

    // .position-relative{
    //   width: 36%;
    // }
    button {
      width: 30px;
      min-height: 33px;
      border: 1px solid #c1ccd3;
      background: transparent;
      margin-left: 16px;
      outline: 0;
    }
    .closs-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      padding: 0;
      width: auto;
      border: 0;
    }
  }
  .availability-time {
    min-height: 55px;
    width: 100%;
    max-width: 120px;
    // width: 337px;
  }
  .availability-select {
    min-height: 36px;
    width: 205px;
  }
  .availability {
    -webkit-appearance: none;
  }

  .add-list {
    position: absolute;
    width: 185px;
    border: 1px solid #c1ccd3;
    background: #fff;
    left: 16px;
  }
  .repeat-date {
    span {
      padding: 8px 10px;
      border: 1px solid #c1ccd3;
      height: 40px;
    }
    .tostyle {
      padding: 10px 21px;
    }
    .react-datepicker-wrapper {
      input {
        height: 40px;
        border-radius: 0;
        border: 1px solid #c1ccd3;
        padding: 10px;
        border-left: 0;
      }
    }
    @media (max-width: 992px) {
      flex-wrap: wrap;
    }
  }
}

.submit-wrapper {
  button {
    background: transparent;
    box-shadow: none;
    outline: 0;
    border: 1px solid #c1ccd3;
    padding: 5px 13px;
  }
  &.margin-top {
    margin-top: 100px;
    margin-bottom: 20px;
  }
}
.availability-group-outer {
  @media (min-width: 1200px) and (max-width: 1350px) {
    padding-right: 0px;
  }
}
.new-availability-group {
  border: 1px solid;
  padding: 10px 25px;
  margin-bottom: 100px;
  @media (min-width: 1200px) and (max-width: 1350px) {
    padding: 10px;
  }
  @media (max-width: 576px) {
    padding: 10px;
  }
  label,
  p {
    font-size: 13px;
  }
  .days-circle {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid #c1ccd3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    &.active {
      background: #c4e2ff;
    }
  }
  .resource-wrap {
    border: 1px solid #c1ccd3;
    padding: 5px;
    justify-content: space-around;
    select {
      // min-width: 100px;
      min-width: 80px;
    }
    .range {
      button {
        min-height: 33px;
        width: 33px;
      }
      input {
        height: 33px;
        width: 50px;
      }
    }
    .cross-wrap {
      width: 30px;
    }
    .cross {
      background: transparent;
      border: 0;
      padding: 0;
      box-shadow: none;
      outline: 0;
      // margin-right: 10px;
      margin: 0 3px 0 5px;
    }
  }
}
/* Attraction tab */

.attraction-wrapper {
  width: 100%;
  padding-left: 10px;
  @media (min-width: 768px) and (max-width: 1024px) {
    padding-left: 0px;
  }
  @media (max-width: 767px) {
    padding-left: 0px;
    margin-bottom: 20px;
  }
  .booking-rowbr {
    border: 0px;
  }
  .edit-wrapper {
    margin-bottom: 28px;
    .attraction-border {
      border-top: 0px;
      border-bottom: 0px;
    }
  }
  .attraction-btn {
    .btn {
      width: 100%;
      background-color: #f7f7f7;
      color: #000;
      border: 1px solid #dedede;
      text-align: left;
    }
    .btn:active {
      background-color: #e8e8e8;
      color: #000;
      border: 1px solid #e8e8e8;
    }
    .btn:focus {
      box-shadow: none !important;
    }
    .dropdown-toggle::after {
      position: absolute;
      right: 20px;
      top: 18px;
    }
    .dropdown-menu {
      width: 100%;
      background-color: #f7f7f7;
      border: 1px solid #dedede;
    }
    .c-form-row {
      margin-top: 0px !important;
      border: 0px;
    }
    .Dropdown-limit {
      height: 350px;
      overflow-y: scroll;
      top: 37px !important;
      transform: none !important;
    }
  }
  .attraction {
    font-size: 18px;
    margin-bottom: 11px;
  }
  .attraction-btns {
    margin: 100px 0 20px;
  }
}

.c-form-row {
  border: 1px solid #c1ccd3;
  padding: 8px 8px 8px;
  .question-input {
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 0;
    margin-left: 7px;
  }
  .option-sec {
    padding: 8px 8px 8px;
    margin-left: 20px;
    position: relative;
    display: flex;
    .radio-sec-w {
      max-width: 280px;
      margin-left: 20px;
    }
    .fad {
      margin-top: 12px;
      margin-right: 17px;
    }
    .form-check-input {
      margin-top: 10px !important;
      margin-left: 22px !important;
    }
  }

  button {
    background: transparent;
    box-shadow: none;
    outline: 0;
    border: 1px solid #c1ccd3;
    padding: 5px 13px;
  }
  input {
    border-radius: 0;
  }
  .form-col {
    width: 50%;

    .fnt-13 {
      font-size: 13px;
    }
  }
}
.edit-wrapper {
  select {
    width: 200px;
    border-radius: 0;
    height: 45px !important;
  }
  .icon {
    background: transparent;
    border: 0;
    padding: 0;
    outline: 0;
    img {
      width: 28px;
    }
  }
  .form-col-w {
    width: 100%;
    max-width: 350px;
  }
}
.availability-groups {
  border: 1px solid #c1ccd3;
  padding: 10px 7px 10px 17px;
  border-left: 0;
  flex-wrap: wrap;
  // height: 55px;
  button {
    width: 30px;
    min-height: 33px;
    border: 1px solid #c1ccd3;
    background: transparent;
    margin-left: 16px;
    outline: 0;
  }
  .closs-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 6px;
    padding: 0;
    width: auto;
    border: 0;
  }
}
.add-list {
  position: absolute;
  width: 185px;
  border: 1px solid #c1ccd3;
  background: #fff;
  left: 16px;
  z-index: 1;
  height: 200px;
  overflow-y: scroll;
}
.submit-wrapper {
  margin: 20px 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  button {
    background: transparent;
    box-shadow: none;
    outline: 0;
    border: 1px solid #c1ccd3;
    padding: 5px 13px;
  }
  &.margin-top {
    margin-top: 100px;
    margin-bottom: 20px;
  }
}
.react-time-picker__inputGroup {
  width: 48px;
  min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  // padding: 0px !important;
  box-sizing: content-box;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  padding: 16% 2px !important;
}
.Scheduling__items__contents {
  .react-time-picker__inputGroup {
    padding: 0 !important;
  }
}

.react-time-picker__inputGroup__input {
  height: unset !important;
}
.position-relative1 {
  position: absolute;
  right: 8px;
}
.backdrop {
  // z-index: -1;
  position: fixed;
  display: flex;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
}
@media (max-width: 768px) {
  .Tours__right {
    padding: 0 !important;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .Tours__right {
    padding: 0 !important;
  }
}
@media (min-width: 767px) and (max-width: 820px) {
  .Tours__progress-container {
    max-width: 150px;
  }
}
@media (max-width: 1220px) {
  .add-list {
    left: -139px !important;
  }
}

@media (max-width: 767px) {
  .Tours__left_disable {
    display: none;
    width: 100%;
  }
  .Tours__left_active {
    display: block;
    margin-right: 20px;
  }
  .Tours__right {
    padding: 0px;
  }
  .Widget_widgetBody__1QTFl {
    margin-right: 10px;
    overflow-x: auto;
  }
  .range-wrapper {
    flex-wrap: nowrap !important;
  }
}

@media (min-width: 768px) {
  .nav-link {
    display: none;
  }
}
@media (max-width: 576px) {
  .Tours__right {
    padding: 0 !important;
  }
  .mob-change {
    min-width: unset !important;
    max-width: 60px;
    padding: 5px;
  }
  .mob-w-change {
    max-width: 91px;
  }
  .Tours__footer-container {
    button {
      width: 6.5rem !important;
      margin-right: 1rem !important;
    }
  }
  .align-set {
    margin-left: 72px;
    margin-right: 12px;
  }
}
.Tours__inputs-container {
  overflow: unset !important;
}
.admin-form-width {
  max-width: 500px;
}
@media (min-width: 1550px) {
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 20px;
    padding-left: 20px;
  }
}

/* Pricing tab */

.price-lable {
  margin-bottom: 16px;
}
.pricing-input {
  display: block !important;
  .adult-content {
    display: flex;
    margin-bottom: 23px;
    .adult-input {
      text-align: center;
      width: 75% !important;
    }
    .width {
      width: 12% !important;
      & #text {
        width: 100%;
        text-align: center;
      }
    }
  }
  .form-control {
    width: 20%;
    margin-right: 20px !important;
    height: calc(2.25rem + 7px) !important;
    border-radius: 0px;
    padding: 2px;
    @media (max-width: 768px) {
      margin-top: 0px !important;
      width: 33%;
    }
  }
  button {
    padding: 8px 16px;
    background-color: transparent;
    border: 1px solid #c1ccd3;
    margin-left: 20px;
  }
  button:focus {
    outline: 0px;
  }
  .usdolor-content {
    border: 1px solid #c1ccd3;
    padding: 7px;
    margin: 0px;
    border-right: 0px;
  }
}

.advertisted-input {
  margin-bottom: 23px;
  .usdolor-content {
    border: 1px solid #c1ccd3;
    padding: 7px;
    margin: 0px;
    border-right: 0px;
  }
  input {
    text-align: center;
    width: 31% !important;
    height: calc(2.25rem + 7px) !important;
    border-radius: 0px;
    @media (max-width: 768px) {
      width: 76% !important;
    }
  }
}

.quantity-input {
  display: flex;
  margin-top: 7px;
  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 37px;
  }
  .min-content {
    display: flex;
    align-items: baseline;
    margin-right: 19px;
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }
  .max-content {
    display: flex;
    align-items: center;
  }
  .usdolor-content {
    margin-right: 1rem !important;
    margin: 0px;
  }
  button {
    padding: 7px 12px;
    border: 1px solid #a7a7a7;
    background-color: transparent;
    border: 1px solid #c1ccd3;
  }
  button:focus {
    outline: 0px;
  }
  input {
    padding: 17px 3px;
    border-radius: 0px;
    margin-right: 0px;
    width: 65px;
    border-left: 0px;
    border-right: 0px;
    border-color: #c1ccd3;
    text-align: center;
    height: 37px;
    @media (max-width: 768px) {
      width: 65px !important;
    }
  }
}
.Tours__left_disable {
  width: 23%;
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 28%;
  }
  @media (max-width: 768px) {
    width: 45%;
  }
}

.vehicleinputext {
  input {
    width: 100%;
    padding: 0.35rem;
    padding-left: 28px;
    border: 1px solid #000;
  }
  .fa {
    position: absolute;
    margin: 11px 9px;
    color: #6a6a6a;
  }
}
.vehiclecreate-btn {
  text-align: right;
  button {
    background-color: #ff6262;
    color: #fff;
    border: 0px;
    border-radius: 5px;
    padding: 7px 9px;
    font-weight: 600;
  }
}
.vehiclesearch {
  button {
    padding: 7px 15px;
    border: 1px solid #000;
    background-color: transparent;
  }
}
.editcolor {
  color: #ff6262;
  font-weight: 600;
  text-decoration: none;
}
