.header {
  width:100%;
  z-index:98;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  background: #fff;
  padding: 1.2rem 1.5rem;
  .icons_cont {
    display: flex;
    align-items: center;
    z-index: 5;
    .active-title {
      color: #616161;
      font-size: 1.125rem;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
    .icons {
      margin-right: 20px;
      font-size: 19px;
      color: #002b49 !important;
      @media (max-width: 768px) {
        margin-right: 14px;
      }
    }
    .topNavbarIcons {
      background-color: transparent;
      border: none;
      padding: 0;
    }
  }
  .user_cont {
    display: flex;
    align-items: center;
    .dropdown {
      .btn:focus {
        box-shadow: none !important;
      }
      .dropdown-menu {
        min-width: 8rem;
      }
    }
  }
}
.user-info {
  display: flex;
  align-items: center;
  p {
    margin: 0;
    margin-right: 24px;

    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
}