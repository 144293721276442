.BookingModal {
  &__map {
    padding-bottom: 0.5rem;
  }

  &__search_input {
    border: 0.1rem solid rgba(128, 128, 128, 0.5);
    text-transform: none;
    margin-bottom: 0.7rem;
    width: 100%;
    font-size: 0.8rem;

    &:focus {
      outline: none;
    }
  }
}

.pac-container {
  z-index: 100000;
}