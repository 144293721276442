.CreateForm{
  width: 95%;
  margin: 0 auto;
    &__buttons{
      display: flex;
      &-submit{
        background-color: #e05f63;
        border: 0.1rem solid #e05f63;
        color: white;
      }
      &-cancel{
        background-color: #706e6e;
        border: 0.1rem solid #706e6e;
        color: white;
      }
      & button{
        margin-right: 1.5rem;
        padding: 0.2rem 2.5rem;
        border-radius: 5px;
      }
    }
}