.booking-totals{
    padding: 3rem 10%;
    padding-bottom: 6rem;
     @media (max-width:700px) {
          min-width: 100%;
          padding: 1rem 2%;
          padding-top: 3rem !important;
          overflow-x: scroll;
        }

    background: white;
    .seesight-logo{
        margin-bottom: 1rem;
    }
}
.dash-cards{
    width: 100%;
    margin-bottom: 4rem;
    .dash-header{
    width: 100%;
    display: flex;
    align-items: center;
    @media(max-width:1000px){
      width: auto;
      h1{
        font-size: 19px;
        width: 100% !important;
      }
      flex-wrap: wrap;
    }
    h1{
    width: 62%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    }
    }
    
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
    width: 100%;
    gap: 2rem;
    
    .percity-div{
        width: 30%;
         @media (max-width:1500px) {
          min-width: 100%;
        }
        max-height: 400px;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 2rem;
        .card{
            background: #56A4FF;
            color: black;
            width: 45%;
             @media (max-width:1550px) {
          min-width: 25%;
        }
            background: #FEFEFF;
            border: 1px solid #EDEDED;
            box-shadow: 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037);
            border-radius: 10.9322px;
            h2{
                font-size: 20px;
                background: white !important;
            }
            h4{
                font-size: 16px;
                text-align: center;
              font-weight: bold;              
              background: white !important;  
            }
            p{
                font-size: 15px;
                background: white !important;
                text-align: center;
            }
            .children{
             color:#B1E3FF
            }
            .adults{
              color: #A1E3CB;
            }
            .infants{
                color:#BCBDC2
            }
        }
    }
    .card{
        padding: 1rem;
        h1{
            font-size: 2rem;
            text-align: center;
        }
        h2{
            font-size: 1.5rem;
           text-align: center;
        }
    }
}
.round-charts{
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
    justify-content: center;
    div{
        display: flex;
        align-items: center;
    }
}
         .children{
             color:#B1E3FF;
                       }
            .adults{
              color: #A1E3CB;
              ;
            }
            .infants{
                color:#BCBDC2;
               
            }
           
      .rezdy{
        color: #4C4D9F;
       
      }
      .website{
        color:#95A4FC ;
       
      }
      .cancelled{
        color: #D10000 ;
       
      }
      .completed{
        color: #FC6B21;
       
      }
      .circle{
        height: 0.4rem;
        width: 0.4rem;

        position: absolute; 
        left: -0.6rem;   
        bottom: 30%;
        border-radius: 50%;
      }



      .happening{
        .total-values{
          color: black !important;
        }
        
      }
      .blueStates{
        color:#6FC6FE
      }
      .total-values{
      overflow-x: scroll;
        background: #FF6A83;
        color: white;
        border: none;
        min-width: 60%;
        @media (max-width:1500px) {
          min-width: 100%;
        }
        
        background: #FEFEFF;
        border: 1px solid #EDEDED;
        box-shadow: 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037);
        border-radius: 10.9322px;
        padding: 1rem;
        display: flex;
        
        .states{
          width: 30%; 
          h2{
            font-size: 1.3rem;
          }
          display: flex;
          flex-direction: column; 
          @media(max-width:1550px){
            width: 30%;
          }
           @media(max-width:600px){
            width: 100%;
            h2{
              font-size: 1rem;
            }
          }
          
          
        }
       
        .apexcharts-legend{
            display: none !important;
        }
    }