.Reservations {
  padding: 2rem 2rem 2rem 2rem;
  background-color: #f5f5f5;

  & h2 {
    font-size: 1.6rem;
    padding-left: 1rem;
    color: #002b49;
    padding-bottom: 0.5rem;
  }

  &__loader {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 4rem;

    & p {
      padding-top: 0.5rem;
    }
  }

  &__reserved {
    color: #e05f63;
  }

  &__sent {
    color: #41af3e;
  }

  &__header {
    padding-bottom: 3rem;

    & form {
      display: flex;
      width: 100%;

      & .form-input {
        width: 20%;
        margin-right: 4rem;
      }

      & button {
        background-color: #e05f63;
        border: 0.1rem solid #e05f63;
        color: white;
        padding: 0.1rem 2.2rem;
        border-radius: 5px;
        margin-right: 1.5rem;
      }

      & button:disabled {
        background-color: #e05f638c;
        border: 0.1rem solid #e05f6331;
        color: white;
      }

    }
  }

  &__container {
    display: flex;
    width: 100%;
  }
}

#Reservations-loader {
  display: inline-block;
  width: 60px;
  height: 60px;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }

  border: 3px solid #ff6262;
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}