.Reservation {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  &-log {
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px solid #ced4da;
    border-radius: 10px;
    margin-bottom: 1rem;
    padding: 1.2rem;
    font-size: 0.85rem;
    width: 100%;
    margin-right: 1rem;
    box-shadow: 2px 4px 15px -5px rgba(0, 0, 0, 0.1);

    & p {
      padding: 0;
      margin: 0;
    }

    &-header {
      display: flex;
      justify-content: space-between;

      & h2 {
        font-size: 1rem;
      }

      & select {
        width: 30%;
        font-size: 0.9rem;
      }
    }

  }
}