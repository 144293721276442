a[data-toggle="collapse"] {
  position: relative;
}

.sidebar-dropdown::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.wrapper {
  display: flex;
  height: 1000px;
  width: 13%;
}

#sidebar.active {
  margin-left: -250px;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #6d7fcc;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

.sidebar-links a{
  padding: 10px;
  font-size: 1em;
  display: block;
  color: #fff;
  min-width: 15rem;
  padding-left: 0.8rem;
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
}
.sidebar-links button{
  display: flex;
  padding: 10px;
  font-size: 1.1em;
  color: #002b49;
  background: #fff;
  min-width: 260px;
  border: none;
}

.sidebar-links a.selectedItem {
  color: #002b49;
  background: #fff;
}


#sidebar ul li a:hover {
  color: #002b49;
  background: #fff;
}

ul ul a {
  padding-left: 30px !important;

}

.unselected {
  color: #6d7fcc;
  background: #002b49;
}

.sidebar-links-icons {
  & i {
    float: left;
    margin-right: 15px;
    line-height: 25px;
    width: 30px;
    text-align: center;
  }
}

.offcanvas-wrapper{
  width :15rem !important
}

.offcanvas-body {
.wrapper-main {
  position: absolute;
  top: 0;
  height: 100%;
  .sidebar-main {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: #002b49;
    transition: all 0.3s ease 0s;
   
    &-show{
      width: 15rem;
    }
    &-hide{
      width: 0px;
    }
    .simple-text {
      padding: 5px 0;
      display: inline-block;
      font-size: 1.9rem;
      white-space: nowrap;
      font-weight: 400;
      line-height: 30px;
      overflow: hidden;
      color: #fff;
      pointer-events: none;
      
    }
    .sidebar-wrapper {
      position: relative;
      height: calc(100vh - 75px);
      overflow: auto;
      z-index: 4;
      padding-bottom: 30px;
      #sidebar {
        .sidebar-links-list {
          list-style: none;
          padding: 0px;
          li {
            margin-bottom: 8px;
          }
        }
      }
    }
    .sidebar-logo {
      padding: 15px 16px;
      margin: 0;
      display: block;
      position: relative;
      z-index: 4;
      text-align: center;
      & a{
        text-decoration: none;
      }
      &-show{
        &.simple-text{
          opacity: 1;
          font-size: 1.9rem;
          transition: none;
        }
      }
      &-hide{
        &.simple-text{
          opacity: 0;
          font-size: 0;
          transition: opacity 0.15s , font-size 1s;
        }
      }
      @media (max-width: 767px) {
        margin-top: 74px;
        text-align: left;
      }
    }
  }
}
}