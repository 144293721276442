@import '../../../Bookings/utils/variables';

.Scheduling {
  &__draggable {
    display: flex;
    flex-direction: column;
    font-size: 0.89rem;
    height: auto;
    min-height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.231);
    border-radius: 12px;
    margin-bottom: 0.5rem;
    margin-right: 0.7rem;
    padding: 0.7rem 0.7rem 0.7rem 0.85rem;
    box-shadow: 2px 4px 15px -5px rgba(0, 0, 0, 0.1);

    &.inVehicle {
      width: 190px;
      overflow: hidden;
      white-space: nowrap;

      &:hover {
        width: auto;
        min-width: 190px;

      }
    }

    &.inRoot {
      & .hideoverflow {

        &:hover {
          overflow: visible;
          white-space: break-spaces;
        }

      }

      &.disabled {
        background-color: #e05f6338;
      }
    }

    &.transform {
      & .hideoverflow {

        &:hover {
          white-space: nowrap;
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
        }

      }
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & i {
        color: #002b49;
        font-size: 1.1rem;
      }

      &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &-id {
        display: none;
      }

      &-contact {
        display: none;
      }

      &-units_1 {
        display: block;
      }

      &-units_2 {
        display: none;
      }

      &-status {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin-right: 0.35rem;

        &.urgent {
          background-color: $urgent;
        }

        &.pending {
          background-color: $pending;
        }

        &.needsreview {
          background-color: $needsreview;
        }

        &.noshow {
          background-color: $noshow;
        }

        &.cancelled {
          background-color: $cancelled;
        }

        &.confirmed {
          background-color: $confirmed;
        }

        &.completed {
          background-color: $completed;
        }

        &.pickuptimesent {
          background-color: $pickuptimesent;
        }

        &.informationrequested {
          background-color: $informationrequested;
        }

        &.completed-complaint {
          background-color: $completed-complaint;
        }
      }
    }

    & .hideoverflow {
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;

    }

    &:hover {
      .Scheduling__draggable-info-contact {
        display: block;
      }
      .Scheduling__draggable-info-id {
        display: block;
      }
      .Scheduling__draggable-info-units_1 {
        display: none;
      }
      .Scheduling__draggable-info-units_2 {
        display: block;
      }
    }
  }
}

#popupOpen {
  &:hover {
    color: #115688;
  }
}