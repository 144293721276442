.TaskDashBoard {
  &__container {
    position: absolute;
    top: 50px;
    right: 313px;
    width: 50%;
    margin: 100px auto;
  }

  &__options {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    & select {
      width: 50%;
      margin-right: 20px;
    }
  }
  &__header {
    border: 1px solid black;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    height: 45px;

    & i {
      margin-right: 20px;
    }

    & label {
      font-weight: 600;
      margin-bottom: 0px;

      & span {
        margin-left: 15px;
        font-weight: 300;
        font-size: 10px;
      }
    }

    .city_type {
      width: 50%;
      justify-content: space-around;
      display: flex;
    }
  }

  &__tasks {
    margin-left: 0px;
    margin-top: 10px;
    width: 100%;
    height: 55px;
    @media (max-width: 768px) {
      height: auto;
    }

    .firstClassIconDiv {
      margin-top: 17px;
      & i {
        margin-left: 15px;
      }
    }

    .first,
    .second,
    .third {
      border: 1px solid black;
      justify-content: space-between;
    }
    .second,
    .third {
      text-align: center;
      padding-top: 15px;
      @media (max-width: 768px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

.card {
  border: none;
}

.statusStyle {
  &__red {
    width: 10px;
    margin: 6px 8px;
    border-radius: 50px;
    background-color: #ff565a;
  }

  &__orange {
    width: 10px;
    margin: 6px 8px;
    border-radius: 50px;
    background-color: #ffb558;
  }

  &__green {
    width: 10px;
    margin: 6px 8px;
    border-radius: 50px;
    background-color: #00dc85;
  }

  &__black {
    width: 10px;
    margin: 6px 8px;
    border-radius: 50px;
    background-color: lightgray;
  }
}

.taskName {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  margin-left: 7px;
}
.taskIcon {
  padding-top: 18px;
  margin-right: 8px;
}

.done {
  color: rgb(0, 220, 133);
}

.urgent {
  color: rgb(255, 86, 90);
}

.pending {
  color: rgb(255, 181, 88);
}

.need_Attention {
  color: rgb(255, 86, 90);
}

.AddNewTask__Wrapper {
  border: 1px solid black;
  margin-top: 10px;

  .subtask {
    width: 50%;
    margin-left: 80px;
    padding: 5px 20px;
    margin-top: 40px;
  }

  .AddNewTask {
    position: relative;

    & input {
      border: none;
    }

    .taskIcon {
      margin-left: 20px;
    }

    .plusIcon {
      font-size: 30px;
      position: absolute;
      right: 15px;
    }
  }
}

.newTask__select {
  margin-left: 12%;
  width: 60%;
  justify-content: space-evenly;

  & select {
    width: 30%;
  }
}
