.loader-wrapper {
    display: flex;
    justify-content: space-evenly;
  }

.loader {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .circle-1,
  .circle-2,
  .circle-3 {
    position: absolute;
    top: 0;
    left: 0;
    animation: spin 1.5s infinite linear;
  }

  .circle-2 {
    position: absolute;
    top: 0;
    left: 0;
    animation: spinOpp 1.5s infinite linear;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinOpp {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  