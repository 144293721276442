.Notes {
  &__wrapper {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  &__note-editing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 1.2rem;
    min-height: 2.9rem;
    margin-bottom: 0.5rem;
    border-radius: 25px;
    border: 0.1rem solid rgba(200, 196, 196, 0.74);
    color: #e05f63;
  }

  &__note {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 1rem 0.3rem 1.2rem;
    min-height: 2.9rem;
    margin-bottom: 0.5rem;
    border: 0.1rem solid rgba(200, 196, 196, 0.74);
    border-radius: 25px;

    &-container {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &-msg {
      width: 100%;
      display: flex;
      flex-direction: column;

      &-date {
        color: rgb(1, 71, 121);
      }
    }

    &-edit-form {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & select {
        border: none;
        font-size: 0.9rem;

        &:focus {
          outline: none;
        }
      }

      & input {
        width: 75%;
        border: none;

        &:focus {
          outline: none;
        }
      }

      &-btn {
        & button {
          background-color: transparent;
          border: none;
          font-size: 1.4rem;
          margin-left: 0.2rem;
          color: #002b49;
        }

        & i {
          padding: 0 0.3rem;
        }

        & i:hover {
          color: #0161a5;
        }
      }
    }

    &-new {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem 1.2rem;
      min-height: 2.9rem;
      margin-bottom: 0.5rem;
      border: 0.1rem solid rgba(200, 196, 196, 0.74);
      border-radius: 25px;
      background-color: #f8f8f8;

      & input {
        background-color: #f8f8f8;
      }
    }

    &-add-form {
      margin-top: 1.5rem;

      &-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 500;
        width: 100%;
        color: rgba(65, 63, 63, 0.5);
      }
    }

    &-options {
      &-btn {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        padding: 0 0.5rem;
      }

      &-container {
        display: none;
      }

      &-container.open {
        display: flex;
        flex-direction: row;
        width: 15%;
        margin-right: 0.7rem;

        & button {
          background-color: transparent;
          border: none;
          font-size: 1.1rem;
          margin-right: 0.1rem;
          color: #002b49;
        }

        & i {
          padding: 0 0.3rem;
        }

        & i:hover {
          color: #0161a5;
        }
      }
    }
  }
}